@font-face {
  font-family: 'DSEG7ModernMini-Regular';
  src: url(./fonts/DSEG7-Modern/DSEG7ModernMini-Regular.woff) format('woff');
}

@font-face {
  font-family: 'rounded-mplus-1p';
  src: url(./fonts/Rounded-Mplus-1p/rounded-mplus-1p-medium.ttf) format('truetype');
}

body {
  min-width: 1024px !important;
  margin: 0;
  font-family:
    'rounded-mplus-1p',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  overflow: auto;
}

body ::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

body ::-webkit-scrollbar-track {
  background: #fff !important;
  border-radius: 20px !important;
}

body ::-webkit-scrollbar-thumb {
  border: 1px solid #fff !important;
  border-radius: 20px !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'rounded-mplus-1p', sans-serif !important;
}

button {
  font-family: inherit !important;
}
